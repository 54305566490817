// src/components/Sidebar.tsx
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaBars, FaTachometerAlt, FaUsers, FaUserTie, FaChartBar, FaSignOutAlt } from 'react-icons/fa';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { logoutUser } from '../slices/authSlice';
import { hasPermission } from '../utils/permissionUtils';
import { Permission, Role } from '../utils/permissions';

const Sidebar = () => {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(true);
    const role = useAppSelector((state) => state.auth.role) as Role;

    const navItemClass = ({ isActive }: { isActive: boolean }) =>
        `flex items-center px-4 py-2 mt-5 transition-colors duration-200 transform ${
            isActive ? 'bg-gray-200 text-gray-700' : 'text-gray-600 hover:bg-gray-200 hover:text-gray-700'
        }`;

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        dispatch(logoutUser());
    };

    return (
        <div className={`flex flex-col ${isOpen ? 'w-64' : 'w-20'} h-screen px-4 py-8 bg-white border-r transition-all duration-300`}>
            <div className="flex items-center justify-between">
                {isOpen && <h2 className="text-3xl font-semibold text-gray-800">CRM</h2>}
                <button onClick={toggleSidebar} className="text-gray-500 focus:outline-none">
                    <FaBars />
                </button>
            </div>

            <div className="flex flex-col justify-between flex-1 mt-6">
                <nav>
                    <NavLink to="/dashboard" className={navItemClass}>
                        <FaTachometerAlt className="w-5 h-5" />
                        {isOpen && <span className="mx-4 font-medium">Дашборд</span>}
                    </NavLink>

                    {/* Ліди доступні всім ролям з дозволом VIEW_LEAD */}
                    {hasPermission(role, 'VIEW_LEAD') && (
                        <NavLink to="/leads" className={navItemClass}>
                            <FaUsers className="w-5 h-5" />
                            {isOpen && <span className="mx-4 font-medium">Ліди</span>}
                        </NavLink>
                    )}

                    {/* Афіліати доступні з дозволом VIEW_AFFILIATE */}
                    {hasPermission(role, 'VIEW_AFFILIATE') && (
                        <NavLink to="/affiliate" className={navItemClass}>
                            <FaChartBar className="w-5 h-5" />
                            {isOpen && <span className="mx-4 font-medium">Афіліати</span>}
                        </NavLink>
                    )}

                    {/* Групи доступні з дозволом VIEW_GROUP */}
                    {hasPermission(role, 'VIEW_GROUP') && (
                        <NavLink to="/groups" className={navItemClass}>
                            <FaUserTie className="w-5 h-5" />
                            {isOpen && <span className="mx-4 font-medium">Групи</span>}
                        </NavLink>
                    )}

                    {/* Юзери доступні з дозволом VIEW_USER */}
                    {hasPermission(role, 'VIEW_USER') && (
                        <NavLink to="/users" className={navItemClass}>
                            <FaUsers className="w-5 h-5" />
                            {isOpen && <span className="mx-4 font-medium">Юзери</span>}
                        </NavLink>
                    )}
                </nav>

                <div className="mt-6">
                    <button
                        className="flex items-center px-4 py-2 text-gray-600 transition-colors duration-200 transform hover:bg-gray-200 hover:text-gray-700"
                        onClick={handleLogout}
                    >
                        <FaSignOutAlt className="w-5 h-5" />
                        {isOpen && <span className="mx-4 font-medium">Вийти</span>}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
