// src/pages/Leads.tsx

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { loadLeads, addLead, setCurrentPage, setLimit } from '../slices/leadsSlice';
import Drawer from '../components/Drawer';
import LeadFilterComponent from '../components/LeadFilterComponent';
import Pagination from '../components/Pagination';
import { CreateLeadPayload } from '../types/lead';
import { hasPermission } from '../utils/permissionUtils';
import { Role } from '../utils/permissions';
import { loadAffiliates } from '../slices/affiliateSlice';
import { loadUsers } from '../slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { LeadFilters } from '../types/leadFilters';

const statusStyles: Record<string, { text: string; bg: string; shadow: string }> = {
    new: { text: "text-green-900", bg: "bg-green-200", shadow: "bg-green-500" },
    "no answer": { text: "text-yellow-900", bg: "bg-yellow-200", shadow: "bg-yellow-500" },
    "newer answer": { text: "text-purple-900", bg: "bg-purple-200", shadow: "bg-purple-500" },
    "slip away": { text: "text-red-900", bg: "bg-red-200", shadow: "bg-red-500" },
    "not interested": { text: "text-gray-900", bg: "bg-gray-300", shadow: "bg-gray-500" },
    "no pot": { text: "text-orange-900", bg: "bg-orange-200", shadow: "bg-orange-500" },
    callback: { text: "text-blue-900", bg: "bg-blue-200", shadow: "bg-blue-500" },
    reassign: { text: "text-teal-900", bg: "bg-teal-200", shadow: "bg-teal-500" },
    active: { text: "text-green-900", bg: "bg-green-300", shadow: "bg-green-600" },
    "initial call": { text: "text-yellow-900", bg: "bg-yellow-300", shadow: "bg-yellow-600" },
    "invalid language": { text: "text-purple-900", bg: "bg-purple-300", shadow: "bg-purple-600" },
    "wrong info": { text: "text-red-900", bg: "bg-red-300", shadow: "bg-red-600" },
    "wrong number": { text: "text-red-800", bg: "bg-red-900", shadow: "bg-red-400" },
    depositor: { text: "text-green-900", bg: "bg-green-300", shadow: "bg-green-600" },

};

const Leads = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { leads, loading, error, totalPages, currentPage, limit } = useAppSelector((state) => state.leads);
    const { affiliates } = useAppSelector((state) => state.affiliates);
    const { users = [] } = useAppSelector((state) => state.users);
    const role = useAppSelector((state) => state.auth.role) as Role;
    const email = useAppSelector((state) => state.auth.email);

    const [filters, setFilters] = useState<LeadFilters>({
        status: '',
        dateRange: undefined,
        page: currentPage,
        limit: limit,
    });

    useEffect(() => {
        dispatch(loadLeads(filters));
    }, [dispatch, filters]);

    useEffect(() => {
        dispatch(loadAffiliates());
        dispatch(loadUsers());
    }, [dispatch]);

    const canCreateLead = hasPermission(role, 'CREATE_LEAD');

    const [newLead, setNewLead] = useState<CreateLeadPayload>({
        userName: '',
        email: '',
        phone: '',
        country: '',
        language: '',
        affiliate: (affiliates.length > 0 ? affiliates[0].id : 0),
        manager: (users.length > 0 ? users[0].id : 0),
        comment: null,
        status: 'new',
    });

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleCreateLead = () => {
        setNewLead({
            userName: '',
            email: '',
            phone: '',
            country: '',
            language: '',
            affiliate: (affiliates.length > 0 ? affiliates[0].id : 0),
            manager: (users.length > 0 ? users[0].id : 0),
            comment: null,
            status: 'new',
        });
        setIsDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false);
    };

    const handleSaveLead = (e: React.FormEvent) => {
        e.preventDefault();
        const leadToSave = {
            ...newLead,
            affiliate: newLead.affiliate || 0,
            manager: (users.length > 0 ? newLead.manager : 0),
        };
        dispatch(addLead(leadToSave));
        handleCloseDrawer();
    };

    const viewLeadDetails = (leadId: number) => {
        navigate(`/leads/${leadId}`);
    };

    const handleFilter = (appliedFilters: LeadFilters) => {
        setFilters({
            ...appliedFilters,
            page: 1,
            limit: appliedFilters.limit || limit,
        });
        dispatch(setCurrentPage(1));
        if (appliedFilters.limit !== undefined) {
            dispatch(setLimit(appliedFilters.limit));
        }
    };

    const handlePageChange = (page: number) => {
        if (page < 1 || page > totalPages) return;
        setFilters({
            ...filters,
            page: page,
            limit: limit,
        });
        dispatch(setCurrentPage(page));
    };

    // Тепер просто відображаємо leads, без фільтрації за роллю
    const visibleLeads = leads;

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <div className="flex flex-col lg:flex-row justify-between items-center mb-6">
                <h1 className="text-3xl font-bold text-gray-800">Ліди</h1>
                {canCreateLead && (
                    <button
                        onClick={handleCreateLead}
                        className="mt-4 lg:mt-0 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200 flex items-center"
                    >
                        Створити ліда
                    </button>
                )}
            </div>

            <LeadFilterComponent onFilter={handleFilter} />

            <div className="overflow-x-auto">
                <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                    <table className="min-w-full leading-normal">
                        <thead>
                        <tr>
                            {['ID', "Ім'я", 'Email', 'Телефон', 'Країна', 'Мова', 'Афіліат', 'Менеджер', 'Статус'].map((header) => (
                                <th
                                    key={header}
                                    className="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-semibold"
                                >
                                    {header}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {visibleLeads.map((lead, index) => (
                            <tr
                                key={lead.id}
                                className={`cursor-pointer ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-gray-100 transition duration-200`}
                                onClick={() => viewLeadDetails(lead.id)}
                            >
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap">{lead.id}</p>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap">{lead.userName}</p>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap">{lead.email}</p>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap">{lead.phone}</p>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap">{lead.country}</p>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap">{lead.language}</p>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 w-10 h-10">
                                            <img
                                                className="w-full h-full rounded-full"
                                                src="https://via.placeholder.com/40"
                                                alt="Affiliate"
                                            />
                                        </div>
                                        <div className="ml-3">
                                            <p className="text-gray-900 whitespace-no-wrap">{lead.affiliateData.offerName}</p>
                                            <p className="text-gray-600 whitespace-no-wrap text-sm">{lead.affiliateData.offer}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap">{lead.user.email}</p>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                        <span
                                            className={`relative inline-block px-3 py-1 font-semibold leading-tight ${statusStyles[lead.status]?.text || "text-gray-900"} ${statusStyles[lead.status]?.bg || "bg-gray-200"} rounded-full`}
                                        >
                                            <span
                                                aria-hidden="true"
                                                className={`absolute inset-0 opacity-50 rounded-full ${statusStyles[lead.status]?.shadow || "bg-gray-500"}`}
                                            ></span>
                                            <span className="relative capitalize">{lead.status}</span>
                                        </span>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {!loading && visibleLeads.length === 0 && (
                <div className="mt-8 text-center text-gray-600">
                    Немає лідів за обраними фільтрами.
                </div>
            )}

            {totalPages > 1 && (
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            )}

            {canCreateLead && (
                <Drawer isOpen={isDrawerOpen} onClose={handleCloseDrawer} title="Створити нового ліда">
                    <form onSubmit={handleSaveLead} className="space-y-4">
                        <div>
                            <label className="block text-gray-700 mb-2">Ім'я</label>
                            <input
                                type="text"
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={newLead.userName}
                                onChange={(e) =>
                                    setNewLead({ ...newLead, userName: e.target.value })
                                }
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2">Email</label>
                            <input
                                type="email"
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={newLead.email}
                                onChange={(e) =>
                                    setNewLead({ ...newLead, email: e.target.value })
                                }
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2">Телефон</label>
                            <input
                                type="text"
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={newLead.phone}
                                onChange={(e) =>
                                    setNewLead({ ...newLead, phone: e.target.value })
                                }
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2">Країна</label>
                            <input
                                type="text"
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={newLead.country}
                                onChange={(e) =>
                                    setNewLead({ ...newLead, country: e.target.value })
                                }
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2">Мова</label>
                            <input
                                type="text"
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={newLead.language}
                                onChange={(e) =>
                                    setNewLead({ ...newLead, language: e.target.value })
                                }
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2">Афіліат</label>
                            <select
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={newLead.affiliate}
                                onChange={(e) =>
                                    setNewLead({
                                        ...newLead,
                                        affiliate: Number(e.target.value),
                                    })
                                }
                                required
                            >
                                <option value="">Оберіть афіліата</option>
                                {affiliates.map((affiliate) => (
                                    <option key={affiliate.id} value={affiliate.id}>
                                        {affiliate.offerName} - {affiliate.offer}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2">Менеджер</label>
                            <select
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={newLead.manager}
                                onChange={(e) =>
                                    setNewLead({
                                        ...newLead,
                                        manager: Number(e.target.value),
                                    })
                                }
                                required
                            >
                                <option value="">Оберіть менеджера</option>
                                {users.map((manager) => (
                                    <option key={manager.id} value={manager.id}>
                                        {manager.email}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2">Коментар</label>
                            <textarea
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={newLead.comment || ''}
                                onChange={(e) =>
                                    setNewLead({ ...newLead, comment: e.target.value })
                                }
                            ></textarea>
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2">Статус</label>
                            <select
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={newLead.status}
                                onChange={(e) =>
                                    setNewLead({...newLead, status: e.target.value})
                                }
                                required
                            >
                                <option value="new">Новий</option>
                                <option value="no answer">No Answer</option>
                                <option value="newer answer">Newer Answer</option>
                                <option value="slip away">Slip Away</option>
                                <option value="not interested">Not Interested</option>
                                <option value="no pot">No Pot</option>
                                <option value="callback">Callback</option>
                                <option value="reassign">Reassign</option>
                                <option value="active">Active</option>
                                <option value="depositor">Depositor</option>
                                <option value="initial call">initial call</option>
                                <option value="wrong info">wrong info</option>
                                <option value="invalid language">invalid language</option>
                                <option value="wrong number">wrong number</option>
                            </select>
                        </div>
                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={handleCloseDrawer}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition duration-200"
                            >
                                Відмінити
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
                            >
                                Зберегти
                            </button>
                        </div>
                    </form>
                </Drawer>
            )}
        </div>
    );
};

export default Leads;
