// src/slices/leadsSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fetchLeads, createLead, getSingleLead, updateLead as updateLeadService } from '../services/leadService';
import { Lead, CreateLeadPayload, FetchLeadsResponse } from '../types/lead';
import { LeadFilters } from '../types/leadFilters';

interface LeadsState {
    leads: Lead[];
    selectedLead: Lead | null;
    loading: boolean;
    error: string | null;
    total: number;
    totalPages: number;
    currentPage: number;
    limit: number;
}

const initialState: LeadsState = {
    leads: [],
    selectedLead: null,
    loading: false,
    error: null,
    total: 0,
    totalPages: 0,
    currentPage: 1,
    limit: 10, // Початкова кількість на сторінці
};

/**
 * Завантаження лідів з можливістю фільтрації та пагінації
 */
export const loadLeads = createAsyncThunk(
    'leads/loadLeads',
    async (filters: LeadFilters, thunkAPI) => {
        try {
            const token = localStorage.getItem('accessToken') || '';
            const data = await fetchLeads(token, filters);
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

/**
 * Додавання нового ліда
 */
export const addLead = createAsyncThunk(
    'leads/addLead',
    async (leadData: CreateLeadPayload, thunkAPI) => {
        try {
            const token = localStorage.getItem('accessToken') || '';
            await createLead(token, leadData);
            return leadData;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

/**
 * Завантаження деталей окремого ліда
 */
export const loadSingleLead = createAsyncThunk(
    'leads/loadSingleLead',
    async (leadId: number, thunkAPI) => {
        try {
            const token = localStorage.getItem('accessToken') || '';
            const lead = await getSingleLead(token, leadId);
            return lead;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

/**
 * Оновлення ліда
 */
export const updateLead = createAsyncThunk(
    'leads/updateLead',
    async (
        { leadId, manager, status }: { leadId: number; manager: number; status: string },
        thunkAPI
    ) => {
        try {
            const token = localStorage.getItem('accessToken') || '';
            const response = await updateLeadService(token, leadId, manager, status);
            return { leadId, manager, status, message: response.message };
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const leadsSlice = createSlice({
    name: 'leads',
    initialState,
    reducers: {
        clearSelectedLead(state) {
            state.selectedLead = null;
        },
        setCurrentPage(state, action: PayloadAction<number>) {
            state.currentPage = action.payload;
        },
        setLimit(state, action: PayloadAction<number>) {
            state.limit = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadLeads.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loadLeads.fulfilled, (state, action: PayloadAction<FetchLeadsResponse>) => {
                state.loading = false;
                state.leads = action.payload.leads;
                state.total = action.payload.total;
                state.totalPages = action.payload.totalPages;
            })
            .addCase(loadLeads.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(addLead.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addLead.fulfilled, (state, action: PayloadAction<CreateLeadPayload>) => {
                state.loading = false;
                const newLead: Lead = {
                    id: state.leads.length + 1, // Потрібно отримати правильний ID з сервера
                    userName: action.payload.userName,
                    email: action.payload.email,
                    phone: action.payload.phone,
                    country: action.payload.country,
                    language: action.payload.language,
                    comment: action.payload.comment,
                    status: action.payload.status,
                    lastComment: null, // Немає коментарів на момент створення
                    affiliateData: {
                        id: action.payload.affiliate,
                        offerName: '', // Заповніть ці дані за потреби
                        offer: '',
                        url: '',
                        userName: '',
                        referral: '',
                        description: '',
                    },
                    user: {
                        id: action.payload.manager,
                        userName: '', // Заповніть ці дані за потреби
                        email: '',
                        group: null,
                        type: 'user', // Або інший тип за потреби
                    },
                };
                state.leads.unshift(newLead);
            })
            .addCase(addLead.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(loadSingleLead.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.selectedLead = null;
            })
            .addCase(loadSingleLead.fulfilled, (state, action: PayloadAction<Lead>) => {
                state.loading = false;
                state.selectedLead = action.payload;
            })
            .addCase(loadSingleLead.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(updateLead.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateLead.fulfilled, (state, action) => {
                state.loading = false;
                const { leadId, manager, status } = action.payload;
                // Оновлюємо ліда в списку
                const existingLead = state.leads.find((lead) => lead.id === leadId);
                if (existingLead) {
                    existingLead.user.id = manager; // Припускаємо, що user.id - це менеджер
                    existingLead.status = status;
                }
                // Також оновлюємо вибраного ліда, якщо він відкритий
                if (state.selectedLead && state.selectedLead.id === leadId) {
                    state.selectedLead.user.id = manager;
                    state.selectedLead.status = status;
                }
            })
            .addCase(updateLead.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const { clearSelectedLead, setCurrentPage, setLimit } = leadsSlice.actions;

export default leadsSlice.reducer;
