// src/pages/Statistics.tsx
import React from 'react';

const Statistics = () => {
    return (
        <div>
            <h1 className="text-3xl font-bold mb-6">Статистика</h1>
            {/* Додайте ваші компоненти статистики тут */}
            <p>Тут буде відображатися статистика.</p>
        </div>
    );
};

export default Statistics;
