// src/pages/LeadDetails.tsx

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { loadSingleLead, clearSelectedLead, updateLead } from '../slices/leadsSlice';
import { loadComments, addComment, clearComments } from '../slices/commentSlice';
import { loadUsers } from '../slices/userSlice';
import { Comment } from '../types/comment';
import { hasPermission } from '../utils/permissionUtils';
import { Permission, Role } from '../utils/permissions';
import Modal from '../components/Modal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LeadDetails = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { selectedLead, loading, error } = useAppSelector((state) => state.leads);
    const { comments, loading: commentsLoading, error: commentsError } = useAppSelector((state) => state.comments);
    const role = useAppSelector((state) => state.auth.role) as Role;
    const group = useAppSelector((state) => state.auth.group);
    const email = useAppSelector((state) => state.auth.email);
    const { users = [] } = useAppSelector((state) => state.users);

    const [newComment, setNewComment] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editManager, setEditManager] = useState<number | null>(null);
    const [editStatus, setEditStatus] = useState<string>('');

    useEffect(() => {
        if (id) {
            const leadId = parseInt(id, 10);
            if (isNaN(leadId)) {
                navigate('/leads');
            } else {
                dispatch(loadSingleLead(leadId));
                dispatch(loadComments(leadId));
                dispatch(loadUsers());
            }
        }

        return () => {
            dispatch(clearSelectedLead());
            dispatch(clearComments());
        };
    }, [id, dispatch, navigate]);

    useEffect(() => {
        if (!loading && selectedLead) {
            toast.success('Лід успішно оновлено');
        }
    }, [loading, selectedLead]);

    useEffect(() => {
        if (error) {
            toast.error(`Помилка: ${error}`);
        }
    }, [error]);

    const handleAddComment = (e: React.FormEvent) => {
        e.preventDefault();
        if (selectedLead && newComment.trim() !== '') {
            dispatch(addComment({ message: newComment, leadId: selectedLead.id }));
            setNewComment('');
            setIsModalOpen(false);
        }
    };

    const handleOpenEditModal = () => {
        if (selectedLead) {
            setEditManager(selectedLead.user.id);
            setEditStatus(selectedLead.status);
            setIsEditModalOpen(true);
        }
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        setEditManager(null);
        setEditStatus('');
    };

    const handleSaveEdit = (e: React.FormEvent) => {
        e.preventDefault();
        if (selectedLead) {
            const payload: any = { leadId: selectedLead.id };
            if (hasPermission(role, 'EDIT_STATUS')) {
                payload.status = editStatus;
            }
            if (hasPermission(role, 'EDIT_MANAGER') && editManager !== null) {
                payload.manager = editManager;
            }
            dispatch(updateLead(payload));
            handleCloseEditModal();
        }
    };

    const canEditStatus = hasPermission(role, 'EDIT_STATUS');
    const canEditManager = hasPermission(role, 'EDIT_MANAGER');

    const availableManagers = (() => {
        if (role === 'teamLead') {
            return users.filter(u => u.group === group);
        } else if (role === 'user') {
            return users.filter(u => u.email === email);
        } else {
            return users;
        }
    })();

    if (loading) {
        return <div className="p-4">Завантаження...</div>;
    }

    if (error) {
        return (
            <div className="p-4 text-red-500">
                Помилка: {error}
            </div>
        );
    }

    if (!selectedLead) {
        return <div className="p-4">Лід не знайдено.</div>;
    }

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <button
                onClick={() => navigate(-1)}
                className="mb-6 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
            >
                Назад до Лідів
            </button>

            {(canEditStatus || canEditManager) && (
                <button
                    onClick={handleOpenEditModal}
                    className="mb-6 px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition duration-200"
                >
                    Редагувати Лід
                </button>
            )}

            {/* Основна інформація */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-xl font-semibold mb-4">Основна Інформація</h2>
                    <div className="space-y-2">
                        <p><strong>ID:</strong> {selectedLead.id}</p>
                        <p><strong>Ім'я:</strong> {selectedLead.userName}</p>
                        <p><strong>Email:</strong> {selectedLead.email}</p>
                        <p><strong>Телефон:</strong> {selectedLead.phone}</p>
                        <p><strong>Країна:</strong> {selectedLead.country}</p>
                        <p><strong>Мова:</strong> {selectedLead.language}</p>
                        <p><strong>Статус:</strong> {selectedLead.status}</p>
                        <p><strong>Коментар:</strong> {selectedLead.comment || '—'}</p>
                    </div>
                </div>

                {/* Афіліат */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-xl font-semibold mb-4">Афіліат</h2>
                    <div className="space-y-2">
                        <p><strong>ID Афіліата:</strong> {selectedLead.affiliateData.id}</p>
                        <p><strong>Назва Офера:</strong> {selectedLead.affiliateData.offerName}</p>
                        <p><strong>Офер:</strong> {selectedLead.affiliateData.offer}</p>
                        <p><strong>URL:</strong> {selectedLead.affiliateData.url || '—'}</p>
                        <p><strong>Ім'я Користувача:</strong> {selectedLead.affiliateData.userName || '—'}</p>
                        <p><strong>Реферал:</strong> {selectedLead.affiliateData.referral || '—'}</p>
                        <p><strong>Опис:</strong> {selectedLead.affiliateData.description || '—'}</p>
                    </div>
                </div>

                {/* Менеджер */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-xl font-semibold mb-4">Менеджер</h2>
                    <div className="space-y-2">
                        <p><strong>ID Менеджера:</strong> {selectedLead.user.id}</p>
                        <p><strong>Ім'я Користувача:</strong> {selectedLead.user.userName}</p>
                        <p><strong>Email:</strong> {selectedLead.user.email}</p>
                        <p><strong>Група:</strong> {selectedLead.user.group ? selectedLead.user.group : '—'}</p>
                        <p><strong>Тип:</strong> {selectedLead.user.type}</p>
                    </div>
                </div>
            </div>

            {/* Секція коментарів */}
            <div className="mt-10">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-semibold text-gray-800">Історія Коментарів</h2>
                    {(canEditStatus || canEditManager) && (
                        <button
                            onClick={() => setIsModalOpen(true)}
                            className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-200"
                        >
                            Додати Коментар
                        </button>
                    )}
                </div>

                <div className="bg-white p-6 rounded-lg shadow max-h-96 overflow-y-auto">
                    {commentsLoading ? (
                        <div>Завантаження коментарів...</div>
                    ) : commentsError ? (
                        <div className="text-red-500">Помилка: {commentsError}</div>
                    ) : comments.length === 0 ? (
                        <div className="text-gray-600">Немає коментарів.</div>
                    ) : (
                        <ul className="space-y-4">
                            {comments.map((comment: Comment) => (
                                <li key={comment.id} className="flex">
                                    <div className="flex-shrink-0 mr-3">
                                        <img
                                            className="w-10 h-10 rounded-full"
                                            src={`https://ui-avatars.com/api/?name=${comment.userData.userName}`}
                                            alt={comment.userData.userName}
                                        />
                                    </div>
                                    <div>
                                        <div className="bg-gray-200 p-3 rounded-lg">
                                            <p className="text-gray-800">{comment.message}</p>
                                        </div>
                                        <span className="text-xs text-gray-500 mt-1 block">
                                            {new Date(comment.createdAt).toLocaleString()} - {comment.userData.userName}
                                        </span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>

            {/* Модальне вікно для додавання коментаря */}
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title="Додати Коментар">
                <form onSubmit={handleAddComment} className="space-y-4">
                    <div>
                        <label className="block text-gray-700 mb-2">Коментар</label>
                        <textarea
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder="Введіть ваш коментар..."
                            required
                        ></textarea>
                    </div>
                    <div className="flex justify-end space-x-4">
                        <button
                            type="button"
                            onClick={() => setIsModalOpen(false)}
                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition duration-200"
                        >
                            Відмінити
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-200"
                        >
                            Додати
                        </button>
                    </div>
                </form>
            </Modal>

            {/* Модальне вікно для редагування ліда */}
            {(canEditStatus || canEditManager) && (
                <Modal isOpen={isEditModalOpen} onClose={handleCloseEditModal} title="Редагувати Лід">
                    <form onSubmit={handleSaveEdit} className="space-y-4">
                        {canEditManager && (
                            <div>
                                <label className="block text-gray-700 mb-2">Менеджер</label>
                                <select
                                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                    value={editManager || ''}
                                    onChange={(e) => setEditManager(Number(e.target.value))}
                                    required
                                >
                                    <option value="">Оберіть менеджера</option>
                                    {availableManagers.map((user) => (
                                        <option key={user.id} value={user.id}>
                                            {user.userName} ({user.email})
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}

                        {canEditStatus && (
                            <div>
                                <label className="block text-gray-700 mb-2">Статус</label>
                                <select
                                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                    value={editStatus}
                                    onChange={(e) => setEditStatus(e.target.value)}
                                    required
                                >
                                    <option value="new">Новий</option>
                                    <option value="no answer">No Answer</option>
                                    <option value="newer answer">Newer Answer</option>
                                    <option value="slip away">Slip Away</option>
                                    <option value="not interested">Not Interested</option>
                                    <option value="no pot">No Pot</option>
                                    <option value="callback">Callback</option>
                                    <option value="reassign">Reassign</option>
                                    <option value="active">Active</option>
                                    <option value="depositor">Depositor</option>
                                    <option value="initial call">initial call</option>
                                    <option value="wrong info">wrong info</option>
                                    <option value="invalid language">invalid language</option>
                                    <option value="wrong number">wrong number</option>
                                </select>
                            </div>
                        )}

                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={handleCloseEditModal}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition duration-200"
                            >
                                Відмінити
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition duration-200"
                            >
                                Зберегти
                            </button>
                        </div>
                    </form>
                </Modal>
            )}

            <ToastContainer />
        </div>
    );
};

export default LeadDetails;
