// src/services/leadService.ts

import axiosInstance from './axiosConfig';
import { LeadFilters } from '../types/leadFilters';
import { FetchLeadsResponse, CreateLeadResponse, CreateLeadPayload, GetSingleLeadResponse, Lead } from '../types/lead';

/**
 * Отримання всіх лідів з можливістю фільтрації та пагінації
 * @param token Токен доступу
 * @param filters Об'єкт фільтрів
 * @returns Весь об'єкт відповіді
 */
export const fetchLeads = async (token: string, filters?: LeadFilters): Promise<FetchLeadsResponse> => {
    const params: any = {};

    if (filters) {
        if (filters.status) {
            params.status = filters.status;
        }
        if (filters.dateRange) {
            params.dateRange = filters.dateRange;
        }
        if (filters.page) {
            params.page = filters.page;
        }
        if (filters.limit) {
            params.limit = filters.limit;
        }
    }

    const response = await axiosInstance.get<FetchLeadsResponse>('lead/all', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params,
    });
    return response.data;
};

/**
 * Отримання окремого ліда
 * @param token Токен доступу
 * @param leadId ID ліда
 * @returns Об'єкт ліда
 */
export const getSingleLead = async (token: string, leadId: number): Promise<Lead> => {
    const response = await axiosInstance.get<GetSingleLeadResponse>('lead/', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            leadId,
        },
    });
    return response.data.lead;
};

/**
 * Створення нового ліда
 * @param token Токен доступу
 * @param leadData Дані нового ліда
 * @returns Відповідь з повідомленням
 */
export const createLead = async (token: string, leadData: CreateLeadPayload): Promise<void> => {
    const response = await axiosInstance.post<CreateLeadResponse>('lead/', leadData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    if (response.data.message !== 'Lead successfully created') {
        throw new Error('Не вдалося створити ліда');
    }
};

/**
 * Оновлення ліда
 * @param token Токен доступу
 * @param leadId ID ліда
 * @param manager Новий менеджер
 * @param status Новий статус
 * @returns Повідомлення про успіх
 */
export const updateLead = async (
    token: string,
    leadId: number,
    manager: number,
    status: string
): Promise<{ message: string }> => {
    const payload = {
        leadId,
        manager,
        status,
    };

    const response = await axiosInstance.put<{ message: string }>('lead', payload, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
};
