// src/components/LeadFilterComponent.tsx

import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { LeadFilters } from '../types/leadFilters';

interface LeadFilterComponentProps {
    onFilter: (filters: LeadFilters) => void;
}

const statusOptions = [
    'new',
    'no answer',
    'newer answer',
    'slip away',
    'not interested',
    'no pot',
    'callback',
    'reassign',
    'active',
    'depositor',
    'initial call',
    'wrong info',
    'invalid language',
    'wrong number',
];

const LeadFilterComponent: React.FC<LeadFilterComponentProps> = ({ onFilter }) => {
    const [status, setStatus] = useState<string>('');
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);

    const handleApplyFilters = () => {
        const appliedFilters: LeadFilters = {};

        if (status) {
            appliedFilters.status = status;
        }

        if (startDate && endDate) {
            appliedFilters.dateRange = [
                startDate.toISOString(),
                endDate.toISOString(),
            ];
        }

        onFilter(appliedFilters);
    };

    const handleResetFilters = () => {
        setStatus('');
        setStartDate(undefined);
        setEndDate(undefined);
        onFilter({});
    };

    return (
        <div className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-4 mb-6">
            {/* Фільтр за статусом */}
            <div>
                <label className="block text-gray-700 mb-2">Статус</label>
                <select
                    className="w-full px-3 py-2 border rounded"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                >
                    <option value="">Всі статуси</option>
                    {statusOptions.map((statusOption) => (
                        <option key={statusOption} value={statusOption}>
                            {statusOption.charAt(0).toUpperCase() + statusOption.slice(1)}
                        </option>
                    ))}
                </select>
            </div>

            {/* Фільтр за датою */}
            <div>
                <label className="block text-gray-700 mb-2">Дата</label>
                <div className="flex space-x-2">
                    <DatePicker
                        selected={startDate}
                        onChange={(date: Date | null) => setStartDate(date || undefined)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText="Початкова дата"
                        className="w-full px-3 py-2 border rounded"
                        isClearable
                    />
                    <DatePicker
                        selected={endDate}
                        onChange={(date: Date | null) => setEndDate(date || undefined)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        placeholderText="Кінцева дата"
                        className="w-full px-3 py-2 border rounded"
                        isClearable
                    />
                </div>
            </div>

            {/* Кнопки фільтрації */}
            <div className="flex space-x-2">
                <button
                    onClick={handleApplyFilters}
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                    Фільтрувати
                </button>
                <button
                    onClick={handleResetFilters}
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                >
                    Скинути
                </button>
            </div>
        </div>
    );
};

export default LeadFilterComponent;
